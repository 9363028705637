export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/images/favicon-kalkhoff.ico"}],"style":[],"script":[{"hid":"Cookiebot","src":"https://consent.cookiebot.com/uc.js","data-cbid":"aa36018a-db25-496e-8e5d-f6a60019d5ab","data-blockingmode":"auto","type":"text/javascript","defer":true}],"noscript":[],"charset":"utf-8","title":"Checkout"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null