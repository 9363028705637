// @ts-nocheck
import getCart from "../apollo/queries/getCart.gql";
import getCustomerCart from "../apollo/queries/getCustomerCart.gql";

export default defineNuxtPlugin(async (nuxtApp) => {
    const cart = useCart();
    const { $toast } = useNuxtApp();
    const { getToken } = useApollo();
    const checkoutDisabled = useCheckoutDisabled();
    const apolloClientToken = await getToken();
    const runtimeConfig = useRuntimeConfig();
    const cookieCartId = await useCookie(runtimeConfig.public.SHOP.COOKIE_NAME).value?.vue_mqid ?? null;

    checkoutDisabled.value = false;

    nuxtApp.hook('apollo:error', (error) => {
        const operation = error.operation?.operationName;
        const cartResponse = (operation === 'cart') ? error.response?.data?.cart : (operation === 'customerCart') ? error.response?.data?.customerCart : undefined;

        if (cartResponse && ['cart', 'customerCart'].includes(operation)) {
            cartResponse.items = cartResponse.items.filter(item => item !== null);
            cart.value = cartResponse;
            checkoutDisabled.value = true;
        }
    })

    if (apolloClientToken) {
        const { data, error }: { data: any, error: any } = await useAsyncQuery(getCustomerCart);

        if (error.value) {
            $toast.error(error.value.message);
        }

        if (data.value) {
            cart.value = data.value.customerCart;
        }
    } else {
        if (cookieCartId) {
            const { data, error }: { data: any, error: any } = await useAsyncQuery(getCart, { cartId: cookieCartId });

            if (error.value) {
                $toast.error(error.value.message);
            }

            if (data.value) {
                cart.value = data.value.cart;
            }
        } else {
            cart.value = {
                items: []
            }
        }
    }
});
