// @ts-nocheck
import setBillingAddressOnCart from "../apollo/queries/setBillingAddressOnCart.gql";
import setShippingAddressOnCart from "../apollo/queries/setShippingAddressOnCart.gql";

export default defineNuxtPlugin(() => {
    const cart: any = useCart();
    const { useRecaptchaMutation } = useRecaptcha();
    const { $toast } = useNuxtApp();

    return {
        provide: {
            setShippingAddressOnCart: async (address: any) => {
                try {
                    const updatedCustomerAddressInput = {
                        input: {
                            cart_id: cart.value.id,
                            ext_dealer_id: address.ext_dealer_id ?? null,
                            shipping_addresses: [
                                {
                                    address: {
                                        firstname: address.firstname,
                                        lastname: address.lastname,
                                        street: address.street,
                                        city: address.city,
                                        postcode: address.postcode,
                                        country_code: address.country_code,
                                        telephone: address.telephone,
                                    }
                                }
                            ]
                        }
                    }
                    const { mutate } = await useRecaptchaMutation(setShippingAddressOnCart, updatedCustomerAddressInput);
                    const updateCustomerShippingResult: any = await mutate();
                    if (updateCustomerShippingResult.data) {
                        cart.value.shipping_addresses = updateCustomerShippingResult.data.setShippingAddressesOnCart.cart.shipping_addresses;
                        return true;
                    }

                    return false;
                } catch (error) {
                    if (error.message) {
                        $toast.error(error.message);
                    }

                    return false;
                }
            },

            setBillingAddressOnCart: async (address: any) => {
                try {
                    const updatedCustomerAddressInput = {
                        input: {
                            cart_id: cart.value.id,
                            billing_address: {
                                address: {
                                    firstname: address.firstname,
                                    lastname: address.lastname,
                                    street: address.street,
                                    city: address.city,
                                    postcode: address.postcode,
                                    country_code: address.country_code,
                                    telephone: address.telephone,
                                },
                            },
                        }
                    }
                    const { mutate } = await useRecaptchaMutation(setBillingAddressOnCart, updatedCustomerAddressInput);
                    const updateCustomerBillingResult: any = await mutate();
                    if (updateCustomerBillingResult.data) {
                        cart.value.billing_address = updateCustomerBillingResult.data.setBillingAddressOnCart.cart.billing_address;
                        return true;
                    }

                    return false;
                } catch (error) {
                    if (error.message) {
                        $toast.error(error.message);
                    }

                    return false;
                }
            }
        }
    }
})
