import { load } from "recaptcha-v3";

export const useRecaptcha = () => ({
    getRecaptchaToken: async () => {
        const runtimeConfig = useRuntimeConfig();
        const recaptcha = await load(runtimeConfig.public.RECAPTCHA.CLIENT_KEY);
        return await recaptcha.execute('login');
    },

    useRecaptchaMutation: async (query: any, variables: any) => {
        const { getRecaptchaToken } = useRecaptcha();

        return useMutation(query, {
            variables: variables,
            context: {
                headers: {
                    'X-ReCaptcha': await getRecaptchaToken(),
                }
            }
        });
    }
});
