export default defineNuxtPlugin(() => {
    const extractHexColors = (str: string) => {
        const regex = /#(?:[0-9a-fA-F]{3}){1,2}/g;
        const matches = str.match(regex);

        return matches ? matches : [];
    }

    return {
        provide: {
            renderSwatchValue: (value: string) => {
                const hasColor = /#[0-9A-F]{6}$/i.test(value);

                if (hasColor) {
                    const colors = extractHexColors(value);

                    let colorStyles = '';
                    colors.map((color: string, index: number) => colorStyles += `--color-${index}: ${color};`);

                    return `<div class="color__swatch ${colors.length > 1 ? `color__swatch--duo` : ''}" style="${colorStyles}"></div>`;
                }

                return value;
            }
        }
    }
})
