export default defineNuxtRouteMiddleware((to, from) => {
    if (process.server) {
        return
    }

    const STATUS_FAILED = 'failed';
    const route: any = useRoute();
    const cart: any = useCart();
    const steps: any = useSteps();
    const customer: any = useCustomer();

    Object.keys(steps.value).map((key) => steps.value[key].active = (key === to.name));

    const loggedIn = customer.value ? !!(customer.value.email && customer.value.firstname) : false;

    if (to.name === 'customer') {
        if (!cart.value.items) {
            return navigateTo('/cart');
        }

        if ((from.name === 'init' || from.name === 'cart') && loggedIn) {
            steps.value.customer.completed = true;
            return navigateTo('/shipping');
        }
    }

    if (to.name === 'shipping') {
        if (loggedIn) {
            steps.value.customer.completed = true;
            return;
        }

        if (!steps.value.customer.completed) {
            return navigateTo('/cart');
        }
    }

    if (to.name === 'payment') {
        let failedPayment = false;

        if (route.query?.status && route.query.status === STATUS_FAILED) {
            failedPayment = true;
        }

        const hasCartItemAddresses = cart.value?.shipping_addresses?.length > 0;
        const customerStepCompleted = steps.value.customer.completed;
        const shippingStepCompleted = steps.value.shipping.completed;

        if (!hasCartItemAddresses && !failedPayment) {
            if (customerStepCompleted && !shippingStepCompleted) {
                return navigateTo('/shipping');
            }

            return navigateTo('/cart');
        }

        steps.value.customer.completed = true;
        steps.value.shipping.completed = true;
        return;
    }
});
