// @ts-nocheck
import getCustomer from "../apollo/queries/getCustomer.gql";

export default defineNuxtPlugin(async () => {
    const { onLogin, onLogout, getToken } = useApollo();
    await onLogout();

    const { $toast } = useNuxtApp();
    const customer = useCustomer();
    const clientToken = await getToken();
    const runtimeConfig = useRuntimeConfig();
    const cookieCustomerToken = await useCookie(runtimeConfig.public.SHOP.COOKIE_NAME).value?.vue_customer_token ?? null;

    if (clientToken) {
        await getCustomerAfterAuth();
    } else {
        if (cookieCustomerToken) {
            await onLogin(cookieCustomerToken as string).then(() => {
                nextTick(() => getCustomerAfterAuth());
            });
        }
    }

    async function getCustomerAfterAuth() {
        const { data, error }: { data: any, error: any } = await useAsyncQuery(getCustomer);

        if (error.value) {
            $toast.error(error.value.message);
        }

        if (data.value) {
            customer.value = data.value.customer;
        }
    }
});
