// @ts-nocheck
import { setContext } from "@apollo/client/link/context";

export default defineNuxtPlugin(async () => {
    const { clients } = useApollo();
    const storeCode = useStoreCode();
    const localeCode = useLocaleCode();
    const countryCode = useCountryCode();
    const runtimeConfig = useRuntimeConfig();
    const cookieStoreCode = await useCookie(runtimeConfig.public.SHOP.COOKIE_NAME).value?.vue_store_code ?? null;
    const cookieLocaleCode = await useCookie(runtimeConfig.public.SHOP.COOKIE_NAME).value?.vue_locale_code ?? null;
    const cookieCountryCode = await useCookie(runtimeConfig.public.SHOP.COOKIE_NAME).value?.vue_country_code ?? null;

    if (cookieStoreCode) {
        storeCode.value = cookieStoreCode;
    }

    if (cookieLocaleCode) {
        localeCode.value = cookieLocaleCode;
    }

    if (cookieCountryCode) {
        countryCode.value = cookieCountryCode;
    }

    const storeLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                store: cookieStoreCode ?? ''
            }
        }
    });

    return clients?.default.setLink(storeLink.concat(clients.default.link));
});
