import getStoreConfig from "~/apollo/queries/getStoreConfig.gql";

export default defineNuxtPlugin(async () => {
    const storeConfig = useStoreConfig();
    const { data } = await useAsyncQuery(getStoreConfig);

    if (data.value) {
        const requiredMinComplexity = data.value.storeConfig.required_character_classes_number ?? 2;
        const requiredMinLength = data.value.storeConfig.minimum_password_length ?? 8;

        storeConfig.value = {
            ...storeConfig.value,
            required_character_classes_number: parseInt(requiredMinComplexity),
            minimum_password_length: parseInt(requiredMinLength),
        }
    }
})
