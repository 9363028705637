export default defineNuxtPlugin(() => ({
    provide: {
        formatPriceWithCurrency: (price: number, currency: string, store_code: string) => {
            const defaultFormatOptions = {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }

            try {
                const locale = store_code.replace('_', '-');
                return Intl.NumberFormat(locale, defaultFormatOptions).format(price).replace(',00', '');
            } catch {
                return Intl.NumberFormat('int', defaultFormatOptions).format(price).replace(',00', '');
            }
        }
    }
}))
