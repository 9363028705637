export const useSteps = () => useState<object>('steps', () => ({
    customer: {
        index: 1,
        active: false,
        completed: false,
    },
    shipping: {
        index: 2,
        active: false,
        completed: false,
    },
    payment: {
        index: 3,
        active: false,
        completed: false,
    }
}));
