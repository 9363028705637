<template>
  <section class="error">
    <main>
      <div class="error__wrapper">
        <h4>A {{ error.statusCode }} error occurred:</h4>
        <div v-html="error.message" />
      </div>
    </main>
  </section>
</template>

<script>
  export default {
    props: ['error'],
  }
</script>
