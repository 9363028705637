import { default as cartl5ckCViQWgMeta } from "/opt/build/repo/pages/cart.vue?macro=true";
import { default as customerjwKeaDolq0Meta } from "/opt/build/repo/pages/customer.vue?macro=true";
import { default as errorAgWXU9CPFRMeta } from "/opt/build/repo/pages/error.vue?macro=true";
import { default as paymentQ8bq6VmO0YMeta } from "/opt/build/repo/pages/payment.vue?macro=true";
import { default as shippingMKy4Gost8yMeta } from "/opt/build/repo/pages/shipping.vue?macro=true";
import { default as success5PxaWcwlslMeta } from "/opt/build/repo/pages/success.vue?macro=true";
import { default as cartG56hCyeg5uMeta } from "~/pages/cart.vue?macro=true";
export default [
  {
    name: cartl5ckCViQWgMeta?.name ?? "cart",
    path: cartl5ckCViQWgMeta?.path ?? "/cart",
    meta: cartl5ckCViQWgMeta || {},
    alias: cartl5ckCViQWgMeta?.alias || [],
    redirect: cartl5ckCViQWgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: customerjwKeaDolq0Meta?.name ?? "customer",
    path: customerjwKeaDolq0Meta?.path ?? "/customer",
    meta: customerjwKeaDolq0Meta || {},
    alias: customerjwKeaDolq0Meta?.alias || [],
    redirect: customerjwKeaDolq0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/customer.vue").then(m => m.default || m)
  },
  {
    name: errorAgWXU9CPFRMeta?.name ?? "error",
    path: errorAgWXU9CPFRMeta?.path ?? "/error",
    meta: errorAgWXU9CPFRMeta || {},
    alias: errorAgWXU9CPFRMeta?.alias || [],
    redirect: errorAgWXU9CPFRMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/error.vue").then(m => m.default || m)
  },
  {
    name: paymentQ8bq6VmO0YMeta?.name ?? "payment",
    path: paymentQ8bq6VmO0YMeta?.path ?? "/payment",
    meta: paymentQ8bq6VmO0YMeta || {},
    alias: paymentQ8bq6VmO0YMeta?.alias || [],
    redirect: paymentQ8bq6VmO0YMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/payment.vue").then(m => m.default || m)
  },
  {
    name: shippingMKy4Gost8yMeta?.name ?? "shipping",
    path: shippingMKy4Gost8yMeta?.path ?? "/shipping",
    meta: shippingMKy4Gost8yMeta || {},
    alias: shippingMKy4Gost8yMeta?.alias || [],
    redirect: shippingMKy4Gost8yMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/shipping.vue").then(m => m.default || m)
  },
  {
    name: success5PxaWcwlslMeta?.name ?? "success",
    path: success5PxaWcwlslMeta?.path ?? "/success",
    meta: success5PxaWcwlslMeta || {},
    alias: success5PxaWcwlslMeta?.alias || [],
    redirect: success5PxaWcwlslMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/success.vue").then(m => m.default || m)
  },
  {
    path: cartG56hCyeg5uMeta?.path ?? "/",
    name: cartG56hCyeg5uMeta?.name ?? "init",
    meta: cartG56hCyeg5uMeta || {},
    alias: cartG56hCyeg5uMeta?.alias || [],
    redirect: cartG56hCyeg5uMeta?.redirect || undefined,
    component: () => import("~/pages/cart.vue").then(m => m.default || m)
  }
]